import Dropzone from 'dropzone';
import { reloadGridLayout } from '../gridLayout';

Dropzone.autoDiscover = false;

document.addEventListener('turbolinks:load', () => {
  const element = document.querySelector(".js-dropzone");
  if (element !== null && element !== undefined) {
    const grid = document.querySelector(".js-grid");
    if (grid === null || grid === undefined) return;
    const options = {
      url: element.dataset.dropFileUrl,
      withCredentials: true,
      addRemoveLinks: false,
      clickable: false,
      paramName: 'files',
      headers: { 'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content },
      previewsContainer: grid,
      previewTemplate: JSON.parse(element.dataset.dropPreviewTemplate),
      thumbnailWidth: 500,
      thumbnailHeight: 500,
      thumbnailMethod: "contain",
      success: (file, response) => {
        if (response.item) {
          let div = document.createElement('div');
          div.innerHTML = response.item;
          file.previewElement.replaceWith(div.firstChild);
          reloadGridLayout();
        }
      },
      addedfile: function(file) {
        Dropzone.prototype.defaultOptions.addedfile.call(this, file)
        reloadGridLayout();
        setTimeout(() => { file.previewElement.scrollIntoView() }, 500)
      },
    };

    const dropzone = new Dropzone(element, options);
  }
});
