import $ from "jquery"
import magnificPopup from "magnific-popup"

import "../../../../node_modules/magnific-popup/dist/magnific-popup.css"

$(document).on("turbolinks:load", () => {
  const $modalHandlers = $(
    ".js-open-share-popup, .js-open-edit-popup",
  )
  if ($modalHandlers.length > 0) {
    $modalHandlers.magnificPopup({ type: "inline" })

    $(".js-close-popup").on("click", () => {
      $.magnificPopup.close()
    })
  }
})
