import "../../src/styles/components/gridLayout.scss"

import Packery, { bindDraggabillyEvents } from "packery"
import Draggabilly from "draggabilly"
import imagesLoaded from "imagesloaded"

let gridLayout

export function initGridLayout() {
  const grid = document.querySelector(".js-grid")
  if (grid === undefined || grid === null) return

  new imagesLoaded(grid, () => {
    gridLayout = new Packery(grid, {
      itemSelector: ".js-grid-item",
      columnWidth: ".js-grid-sizer",
      gutter: ".js-gutter-sizer",
      percentPosition: true,
      fitWidth: true,
      rowHeight: 10,
    })

    const isDraggable =
      grid.dataset.draggable !== undefined && grid.dataset.draggable !== null
    if (isDraggable) {
      const elements = Array.from(
        document.getElementsByClassName("js-grid-item"),
      )

      for (let i = 0; i < elements.length; i++) {
        let draggie = new Draggabilly(elements[i])
        gridLayout.bindDraggabillyEvents(draggie)
        draggie.on('dragEnd', (event, pointer) => {
          $(grid).trigger("gridDragEnd");
        })
      }
    }
  })
}

export function reloadGridLayout(reloadItems = true) {
  if (reloadItems) gridLayout.reloadItems()
  gridLayout.layout()
}

export function getOrderItems() {
  return gridLayout.getItemElements()
}

document.addEventListener("turbolinks:load", () => {
  initGridLayout()
})
