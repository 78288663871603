import "../../src/styles/components/organizeBook.scss"
import { reloadGridLayout, getOrderItems } from "../gridLayout"
import $ from "jquery"

const initializeOrganizeBook = () => {
  let selectedPages = []

  const $root = $(".js-organize-book")
  const $pages = $(".js-grid-item")

  if ($root.length === 0 || $pages.length === 0) return

  const locales = $root.data("locales")

  const toggleButtons = selectedPages => {
    const $buttons = $(".js-open-move-popup, .js-organize-book-destroy")
    if (selectedPages.length === 0) {
      $buttons.addClass("is-disabled").attr("title", locales.no_pages_selected)
    } else {
      $buttons.removeClass("is-disabled").removeAttr("title")
    }
  }
  toggleButtons(selectedPages)

  const toggleSelected = ($el, selected) => {
    $el.toggleClass("selected", selected)
  }

  const isSelected = key => {
    return selectedPages.indexOf(key) !== -1
  }

  const allSelected = () => {
    return $pages.length > 0 && selectedPages.length === $pages.length
  }

  const unselectAll = () => {
    selectedPages = []

    $pages.each((index, element) => {
      let $el = $(element)
      let key = $el.data("key")

      toggleSelected($el, isSelected(key))
    })

    toggleButtons(selectedPages)
  }

  const selectAll = () => {
    selectedPages = $.map($pages, element => {
      return $(element).data("key")
    })

    $pages.each((index, element) => {
      let $el = $(element)
      let key = $el.data("key")

      toggleSelected($el, isSelected(key))
    })

    toggleButtons(selectedPages)
  }

  $pages.each((index, element) => {
    let $el = $(element)
    let key = $el.data("key")

    $el.on("click", () => {
      if (isSelected(key)) {
        selectedPages.splice(selectedPages.indexOf(key), 1)
      } else {
        selectedPages.push(key)
      }

      toggleSelected($el, isSelected(key))
      toggleButtons(selectedPages)
    })
  })

  let $selectAllButton = $(".js-organize-book-select-all")
  $selectAllButton.on("click", event => {
    event.preventDefault()

    if (allSelected()) {
      unselectAll()
    } else {
      selectAll()
    }
  })

  let $destroyButton = $(".js-organize-book-destroy")
  $destroyButton.on("click", event => {
    event.preventDefault()

    if (selectedPages.length === 0) {
      alert(locales.no_pages_selected)
      return
    }

    let confirmed = confirm(locales.delete_confirmation)
    if (!confirmed) return

    $.ajax({
      url: $destroyButton.data("url"),
      method: "POST",
      data: {
        pages: selectedPages,
      },
      success: data => {
        if (data["status"] === "ok") {
          $pages.filter((index, element) => {
            let $el = $(element)
            let key = $el.data("key")

            if (isSelected(key)) {
              selectedPages.splice(selectedPages.indexOf(key), 1)
              $el.remove()
            }
          })
        }
      },
      error: () => {
        alert(locales.server_error_message)
      },
      complete: () => {
        reloadGridLayout()
      },
    })
  })

  $root.on("gridDragEnd", () => {
    let itemIds = $.map(getOrderItems(), element => {
      return $(element).data("key")
    })

    $.ajax({
      url: $root.data("sort-url"),
      method: "POST",
      data: {
        page_ids: itemIds,
      },
      success: () => {},
      error: () => {
        alert(locales.server_error_message)
      },
      complete: () => {
        reloadGridLayout(false)
        unselectAll()
      },
    })
  })

  $(".js-select-book").on("change", event => {
    $.ajax({
      url: "/books/" + event.target.value + "/move_targets.json",
      method: "GET",
      success: data => {
        $(".js-book-page-move-pages").html(data.content)
      },
      error: () => {
        alert(locales.server_error_message)
      },
    })
  })

  $(".js-open-move-popup").click(function(e) {
    e.preventDefault()
    $(".flashMessage").remove()

    if (selectedPages.length === 0) {
      e.stopImmediatePropagation()
      alert(locales.no_pages_selected)
      return
    }

    const $button = $(this)

    if ($button.hasClass("is-disabled")) {
      return
    }

    $button
      .magnificPopup({
        type: "inline",
        callbacks: {
          open: () => {
            $(".js-book-page-move-form")
              .find(".page-ids")
              .val(selectedPages)
          },
        },
      })
      .magnificPopup("open")
  })
}

$(document).on("turbolinks:load", () => {
  initializeOrganizeBook()
})
