import $ from "jquery"
import Clipboard from "clipboard"
import magnificPopup from "magnific-popup"

$(document).on("turbolinks:load", () => {
  let $shareAddress = $(".js-share-address")
  if ($shareAddress.length > 0) {
    new Clipboard($shareAddress[0])
  }

  let $shareForm = $("#share_form")
  if ($shareForm.length > 0) {
    $shareForm.on("submit", event => {
      event.preventDefault()

      $.ajax({
        url: $(event.target).attr("action"),
        method: "POST",
        dataType: "JSON",
        data: $(event.target).serialize(),
        success: data => {
          if (data.status === "error") {
            alert(data.message)
          } else {
            $("#share-popup").magnificPopup("close")
          }
        },
        complete: () => {
          $shareForm.find("input[type=submit]").prop("disabled", false)
        },
      })
    })
  }
})
