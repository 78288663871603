/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "jquery"
import "jquery-ujs"
import Turbolinks from "turbolinks"
import "../src/application.scss"
import "../src/shareBook/index.js"
import "../src/collaborators/index.js"
import "../src/gridLayout/index.js"
import "../src/modal/index.js"
import "../src/organizeBook/index.js"
import "../src/dropdown/index.js"
import "../src/addFileToBook/index.js"
import "../src/firefoxExtension.js"
import "../src/pageShow.js"
import "../src/stats.js"
import "../src/bookNameForm.js"

Turbolinks.start()
