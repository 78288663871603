import "../typeahead.bundle" // the npm package doesn't work well here (includes its own jquery for some reasons)

$(document).on("turbolinks:load", () => {
  const $collaborators = $(".js-collaborators")
  if ($collaborators.length === 0) return

  $(".js-autocomplete-collaborators").typeahead(
    {
      minLength: 0,
    },
    {
      source: (query, syncResults, asyncResults) => {
        $.ajax({
          url: $collaborators.data("autocomplete-collaborators-url"),
          type: "GET",
          data: {
            email: query,
          },
          success: data => {
            if (
              data === undefined ||
              data.emails === undefined ||
              data.emails.length === 0
            )
              return

            asyncResults(data.emails)
          },
          error: () => {
            asyncResults([])
          },
        })
      },
    },
  )
  $(document).on("submit", "#add_collaborator_form", event => {
    event.preventDefault()
    const $form = $(event.target)
    $(".js-collaborators-error-message").html("")
    $.ajax({
      url: $form.attr("action"),
      method: "POST",
      dataType: "JSON",
      data: $form.serialize(),
      success: data => {
        if (data.status === "ok") {
          $(".js-list-collaborators").append(data.content)
          $form.trigger("reset")
        } else {
          $(".js-collaborators-error-message").html(data.message)
        }
      },
      complete: () => {
        $form.find("input[type=submit]").prop("disabled", false)
      },
    })
  })

  $(document).on("click", ".js-remove-collaborator", event => {
    event.preventDefault()

    $.ajax({
      url: $(event.target).attr("href"),
      method: "GET",
      dataType: "JSON",
      success: data => {
        if (data.status === "ok") {
          $(event.target)
            .closest("li")
            .remove()
        }
      },
    })
  })
})
