import React, { useState } from "react"
import ReactDOM from "react-dom"
import { useDebouncedCallback } from "use-debounce"

import BeatLoader from "react-spinners/BeatLoader"
const Loader = () => (
  <div style={{ paddingTop: 5 }}>
    <BeatLoader size={5} />
  </div>
)

import { FaCheck } from "react-icons/fa"
const SuccessIcon = () => (
  <div style={{ width: "20px", paddingTop: 7 }}>
    <FaCheck />
  </div>
)

import { FaExclamationCircle } from "react-icons/fa"
import { IconContext } from "react-icons"
const ErrorIcon = () => (
  <div style={{ width: "20px", paddingTop: 7 }}>
    <IconContext.Provider value={{ color: "maroon" }}>
      <FaExclamationCircle />
    </IconContext.Provider>
  </div>
)

const BookNameForm = ({ name, updateUrl, label }) => {
  const [currentName, setCurrentName] = useState(name)
  const [state, setState] = useState(undefined)
  const [debouncedSubmit] = useDebouncedCallback(value => {
    setState("submitting")

    fetch(updateUrl, {
      method: "PATCH",
      credentials: "same-origin",
      body: JSON.stringify({
        book: {
          name: value,
        },
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
    })
      .then(response => {
        if (response.status !== 200)
          throw new Error(`Invalid response: ${response.status}`)
        setState("submitted")
      })
      .catch(() => {
        setState("error")
      })
  }, 500)

  return (
    <div style={{ display: "flex" }}>
      <label
        className="label"
        for="book-name-input"
        style={{ marginRight: 6, paddingTop: 6 }}
      >
        {label}
      </label>
      <input
        id="book-name-input"
        style={{ flex: 1 }}
        type="text"
        name="name"
        value={currentName}
        onChange={event => {
          const value = event.target.value
          setCurrentName(value)
          setState(undefined)
          debouncedSubmit(value)
        }}
      />
      <div style={{ flex: 1 }}>
        <div style={{ width: 30, marginLeft: 5 }}>
          {state == "submitting" && <Loader />}
          {state == "submitted" && <SuccessIcon />}
          {state == "error" && <ErrorIcon />}
        </div>
      </div>
    </div>
  )
}

document.addEventListener("turbolinks:load", function(event) {
  const root = document.getElementById("book-name-form")
  if (root !== null) ReactDOM.render(<BookNameForm {...root.dataset} />, root)
})
