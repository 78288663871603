import $ from "jquery"

const dropdownOpen = "is-dropdownOpen"
const elem = ".js-dropdownButton"

$(document).on("click", elem, function() {
  $(this).toggleClass(dropdownOpen)
  $(elem)
    .not($(this))
    .removeClass(dropdownOpen)
})

$(document).on("click", function(event) {
  const $dropdownButton = $(event.target).closest(elem)[0]
  if ($dropdownButton == undefined) {
    $(elem).removeClass(dropdownOpen)
  }
})
