$(document).on("click", ".firefox-extension-install", function() {
  const link = $(this)

  const params = {
    DumBook: {
      URL: link.data("xpi"),
      Hash: link.data("hash"),
      toString() { return this.URL; }
    }
  }

  InstallTrigger.install(params)
})

