import $ from "jquery"

$(document).on("turbolinks:load", () => {
  const $sliders = $(".fakeSlider")
  if ($sliders.length == 0) return

  $(".fakeSlider-controls-prev, .fakeSlider-controls-next").click(() => {
    const scrollTop = $(window).scrollTop()
    $(document).one("turbolinks:load", () => {
      $(window).scrollTop(scrollTop)
    })
  })
})

const triggerClick = elementSelector => {
  const href = $(elementSelector).attr("href")
  if (href === undefined) return
  Turbolinks.visit(href)
}

document.addEventListener("keydown", event => {
  const { key } = event
  switch (event.key) {
    case "ArrowLeft":
      triggerClick(".fakeSlider-controls-prev")
      break
    case "ArrowRight":
      triggerClick(".fakeSlider-controls-next")
      break
  }
})
